import { useEffect, useState } from 'react';
import Api from 'api';
import { useTrans } from './localisation';
import { NEWS_STORAGE_KEY } from 'define/news';

export const useNewsPreview = () => {
  const t = useTrans(['filters']);
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const cachedNews = JSON.parse(sessionStorage.getItem(NEWS_STORAGE_KEY)) ?? [];
    const allTag = { name: `${t('all')}`, type: '' };

    if (cachedNews.length) {
      setNews(cachedNews);
      setTags([allTag, ...getTags(cachedNews)]);
      setIsLoading(false);
    } else {
      Api.getNews()
        .then(res => {
          setNews(res);
          setTags([allTag, ...getTags(res)]);
          sessionStorage.setItem(NEWS_STORAGE_KEY, JSON.stringify(res));
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  return { news, tags, isLoading };
};

function getTags(data) {
  return data
    .map(tag => ({
      name: tag.category,
      type: tag.category,
    }))
    .filter((obj, index, self) => index === self.findIndex(t => t.name === obj.name));
}
