import React, { useState, lazy, Suspense } from 'react';
import cn from 'classnames';
import ImageLink from './ImageLink';
import Loader from 'components/UI/Loader';
import { useNewsPreview } from 'utils/news';
import { useStateModal } from 'utils/hooks';
import { pushCustomEvent } from 'utils/analytics';
import { useLang } from 'utils/localisation';

import * as styles from './TabsWithLinks.module.scss';

const NewsModal = lazy(() => import('components/Modals/NewsModal'));

export default function ArticleCards() {
  const lang = useLang();
  const locales = lang === 'de' ? 'de-DE' : 'en-US';
  const { news, isLoading } = useNewsPreview();
  const { modalState, showModal, hideModal } = useStateModal();
  const [modalData, setModalData] = useState({});

  const handlePushEvent = (action, label) => {
    let options = { action };

    if (label) {
      options = { action, label };
    }
    pushCustomEvent('ndrsn_newsroom', options);
  };

  const getDate = date => {
    const preparedDay = new Date(date).toLocaleDateString(locales, {
      month: 'short',
      day: '2-digit',
    });
    return `${preparedDay}, ${new Date(date).getFullYear()}`;
  };

  const openModal = payload => () => {
    setModalData(payload);
    showModal();
    handlePushEvent('open_modal', payload.title);
  };

  return (
    <div className={cn(styles.linksWrap, styles.imagesWrap)}>
      {isLoading ? (
        <Loader theme="fullHeight" />
      ) : (
        news?.slice(0, 3).map(({ id, title, preview, date }) => {
          const preparedDate = getDate(date);
          return (
            <ImageLink
              key={id}
              title={title}
              img={preview}
              date={preparedDate}
              onClick={openModal({ id, title, date: preparedDate })}
            />
          );
        })
      )}
      <Suspense fallback={null}>
        <NewsModal
          isOpen={modalState.isOpen}
          closeModalCallback={hideModal}
          handlePushEvent={handlePushEvent}
          {...modalData}
        />
      </Suspense>
    </div>
  );
}
