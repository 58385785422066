const Request = require('./request');
const { getApiUrl } = require('../utils/environment');
const API_LINK = `${getApiUrl()}/api/`;

class Api extends Request {
  constructor() {
    super(API_LINK);
  }

  getAllCvs(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get('content/cvs', headers);
  }

  getCaptchaToken() {
    return window.grecaptcha
      .execute('6LfCPMAUAAAAAGPj2JvQRyB45mU8iJabAxT_jLu6', {
        action: 'submit',
      })
      .catch(() => 'no-token');
  }

  getCertificates(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get('content/certificates', headers);
  }

  getEvents(lang, countryCode) {
    const headers = { 'Accept-Language': lang, 'Country-Code': countryCode };

    return this.get('content/events', headers);
  }

  getEventPages(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get('content/events/pages', headers);
  }

  getMapInfo(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get('content/projects/map', headers);
  }

  getOfficesIds(countryCode) {
    return this.get(`content/offices/${countryCode}`);
  }

  getOffices(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get('content/offices', headers);
  }

  getProjectPages(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get('content/projects/pages', headers);
  }

  getProjectsCards(page, lang) {
    const headers = { 'Accept-Language': lang, 'Page-Key': page };

    return this.get(`content/projects/cards`, headers);
  }

  getFullProjectsCards(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get(`content/projects/full-cards`, headers);
  }

  getProjectGalleries(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get('content/projects/galleries', headers);
  }

  getRelatedCases(key, id, lang) {
    const headers = { 'Accept-Language': lang };

    return this.get(`content/projects/related/${key}/${id}`, headers);
  }

  getReviews(page, lang, opts) {
    const headers = { ...opts, 'Accept-Language': lang, 'Page-Key': page };

    return this.get(`content/reviews`, headers);
  }

  getTestimonials(country, lang) {
    const headers = { 'Accept-Language': lang };

    return this.get(`content/reviews/${country}`, headers);
  }

  getTestimonialsWithPagination(country, lang, queries) {
    const headers = { 'Accept-Language': lang };
    const preparedQueries = `?countryCode=${country}&${queries ? queries : ''}`;

    return this.get(`content/reviews/testimonial/pagination${preparedQueries}`, headers);
  }

  getFilters(lang, filterCategory, entity) {
    const headers = { 'Accept-Language': lang };

    return this.get(`content/filter/${filterCategory}/${entity}`, headers);
  }

  getTopCompaniesInfo(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get('content/projects/top', headers);
  }

  getViewCount(id) {
    return this.get(`integration/articles/blueprints/views/${id}`);
  }

  getViewsCount() {
    return this.get(`integration/articles/views`);
  }

  getPhones(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get(`content/phones`, headers);
  }

  getInsightsVideo(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get('content/insights-video', headers);
  }

  getInsightsShorts(lang) {
    const headers = { 'Accept-Language': lang };

    return this.get('content/insights-shorts', headers);
  }

  getNews() {
    return this.get('content/news');
  }

  getNewsById(id) {
    return this.get(`content/news/${id}`);
  }

  sendFormData(data) {
    return this.postFormData('integration/form-data', data);
  }

  setViewCount(id) {
    return this.put(`integration/articles/views/${id}`);
  }

  getDnsCheck(email) {
    const headers = { 'Email-Validation': email };

    return this.get('integration/validation/email', headers);
  }
}

module.exports = new Api();
